<template>
  <div class="list">
    <el-container v-bind:style="{ height: Height + 'px' }">
      <el-main>
        <div class="main_box">
          <div class="bread">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <!-- <el-breadcrumb-item :to="{ path: '/academic' }">
                <span v-if="$route.query.webPage == '1'">中文期刊推荐</span>
                <span v-if="$route.query.webPage == '2'">英文期刊推荐</span>
                <span v-if="$route.query.webPage == '4'">机构订阅</span
                ><span v-if="$route.query.webPage == '3'">个人中心</span></el-breadcrumb-item
              > -->
              <el-breadcrumb-item
                v-if="$route.query.webPage == '1'"
                :to="{
                  path: '/academic',
                  query: {
                    webPage: $route.query.webPage,
                  },
                }"
              >
                <span>中文期刊推荐</span>
              </el-breadcrumb-item>
              <el-breadcrumb-item
                v-if="$route.query.webPage == '2'"
                :to="{
                  path: '/academic',
                  query: {
                    webPage: $route.query.webPage,
                  },
                }"
              >
                <span>英文期刊推荐</span>
              </el-breadcrumb-item>
              <el-breadcrumb-item
                v-if="$route.query.webPage == '3'"
                :to="{
                  path: '/academic',
                  query: {
                    webPage: $route.query.webPage,
                  },
                }"
              >
                <span>个人中心</span>
              </el-breadcrumb-item>
              <el-breadcrumb-item
                v-if="$route.query.webPage == '4'"
                :to="{
                  path: '/academic',
                  query: {
                    webPage: $route.query.webPage,
                  },
                }"
              >
                <span>机构订阅</span>
              </el-breadcrumb-item>
              <el-breadcrumb-item
                >{{ $route.query.subject }}期刊</el-breadcrumb-item
              >
            </el-breadcrumb>
            <router-link :to="{ path: '/academic' }"
              ><el-button type="primary" size="mini" plain
                >返回</el-button
              ></router-link
            >
          </div>
          <el-divider></el-divider>
          <div class="box">
            <div
              v-for="(item, index) in listData"
              :key="index"
              class="journal_list"
            >
              <div class="journal_img">
                <router-link
                  :to="{
                    path: '/journalsDetail',
                    query: { id: item.id, personalized: item.personalized ,subject: item.categories ,webPage: $route.query.webPage},
                  }"
                >
                  <div class="imges">
                    <img
                      :src="
                        'https://push.newacademic.net/cover/journal_cover/' +
                        (item.language == 1 ? item.name : item.cn_name) +
                        '.jpg'
                      "
                      alt=""
                    />
                    <img
                      v-if="item.personalized == 1"
                      class="subscribe_css"
                      src="../../../assets/imgs/icons/customized1.png"
                      alt=""
                    />
                  </div>
                </router-link>
              </div>
              <div class="journal_data">
                <div class="title">
                  <router-link
                    :to="{
                      path: '/journalsDetail',
                      query: { id: item.id, personalized: item.personalized ,subject: item.categories,webPage: $route.query.webPage},
                    }"
                    v-if="item.language == 1"
                    ><h2>
                      {{ item.name }}
                    </h2></router-link
                  >
                  <router-link
                    :to="{
                      path: '/journalsDetail',
                      query: { id: item.id, personalized: item.personalized ,subject: item.categories,webPage: $route.query.webPage},
                    }"
                    v-if="item.language == 0"
                    ><h2>
                      {{ item.cn_name }}
                    </h2></router-link
                  >
                  <el-button
                    type="primary"
                    size="mini"
                    plain
                    v-if="item.isSubscribe == 0"
                    @click="subscribe(item)"
                    >订阅</el-button
                  >
                  <el-popconfirm
                    title="确认取消订阅吗？"
                    v-if="item.isSubscribe == 1"
                    @confirm="subscribe(item)"
                  >
                    <el-button
                      slot="reference"
                      type="primary"
                      size="mini"
                      plain
                      style="background: #b14a75; color: white"
                      >已订阅</el-button
                    >
                  </el-popconfirm>
                </div>
                <div class="tags">
                  <div v-for="(item, index) in item.source" :key="index">
                    <el-tag v-if="item == 'CA 化学文摘(美)(2020)'">CA</el-tag>
                    <el-tag
                      v-if="
                        item == 'CSCD 中国科学引文数据库来源期刊(2021-2022年度)'
                      "
                      type="success"
                      >CSCD</el-tag
                    >
                    <el-tag
                      v-if="
                        item ==
                        'CSCD 中国科学引文数据库来源期刊(2021-2022年度)(扩展版)'
                      "
                      type="info"
                      >CSCD(扩)</el-tag
                    >
                    <el-tag
                      v-if="
                        item == 'CSSCI 中文社会科学引文索引(2021-2022)来源期刊'
                      "
                      type="warning"
                      >CSSCI</el-tag
                    >
                    <el-tag v-if="item == 'CSTPCD'" type="danger"
                      >CSTPCD</el-tag
                    >
                    <el-tag
                      v-if="item == 'JST 日本科学技术振兴机构数据库(日)(2018)'"
                      >JST</el-tag
                    >
                    <el-tag
                      v-if="item == 'Pж(AJ) 文摘杂志(俄)(2020)'"
                      type="success"
                      >Pж(AJ)
                    </el-tag>
                    <el-tag v-if="item == 'SA 科学文摘(英)(2020)'" type="info"
                      >SA</el-tag
                    >
                    <el-tag
                      v-if="
                        item ==
                        'WJCI 科技期刊世界影响力指数报告（2020）来源期刊'
                      "
                      type="warning"
                      >WJCI</el-tag
                    >
                    <el-tag v-if="item == '北大核心'" type="danger"
                      >北大核心</el-tag
                    >
                    <el-tag
                      v-if="item == 'EI 工程索引(美)(2020)'"
                      type="warning"
                      >EI</el-tag
                    >
                    <el-tag
                      v-if="item == 'SCI 科学引文索引(美)(2020)'"
                      type="danger"
                      >SCI</el-tag
                    >
                  </div>
                </div>
                <p>创办单位：{{ item.press }}</p>
                <p>出版周期：{{ item.frequency }}</p>
                <p v-if="item.create_time">
                  创建时间：{{ item.create_time | parseTime("{y}-{m}-{d}") }}
                </p>
                <p>
                  最新更新时间：{{
                    item.update_time | parseTime("{y}-{m}-{d}")
                  }}
                </p>
              </div>
            </div>
            <div class="page-bar" v-if="totalCount > 0">
              <el-pagination
                background
                layout="prev, pager, next"
                :total="totalCount > 10000 ? 10000 : totalCount"
                @current-change="handleCurrentChange"
                :page-size="10"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import Vue from "vue";
import qs from "qs";
import {
  journal_discipline,
  subscribe_subject,
  cancel_subscribe,
  update_article,
} from "@/Api/journals";
export default {
  provide() {
    return {
      reloading: this.reloading,
      imgs: require("../../../assets/imgs/icons/杂志期刊.svg"),
      // Height: 0,
    };
  },
  data() {
    return {
      Height: 0,
      keys: "",
      isRouterAlive: true,
      parameter: {},
      listData: [],
      totalCount: 0,
      total: 0,
      webpage: window.sessionStorage.getItem("webPage"),
      subscribeOr:localStorage.getItem('subscribeOr'),
    };
  },
  mounted() {
    //动态设置内容高度 让footer始终居底   header+footer的高度是190
    // console.log(this.name)
    this.Height = document.documentElement.clientHeight - 80; //监听浏览器窗口变化
    window.onresize = () => {
      this.Height = document.documentElement.clientHeight - 80;
    };
  },
  created() {
    // this.unreads();
    if (this.$route.query.webPage) {
      this.keys = this.$route.query.webPage.toString();
      // this.activeIndex = this.$route.query.webPage.toString();
    }
    // if (window.sessionStorage.getItem("webPage")) {
    //   this.keys = window.sessionStorage.getItem("webPage");
    //   // console.log(this.keys)
    // } else {
    //   this.keys = "1";
    //   window.sessionStorage.setItem("webPage", this.keys);
    // }
    this.parameter = {
      subject:this.$route.query.subject,
      language:this.$route.query.language
    };
    Vue.set(this.parameter, "page", 1);
    Vue.set(this.parameter, "num", 10);
    Vue.set(this.parameter, "user_openid", localStorage.getItem("code"));
    // console.log(this.parameter)
    this.update();
    this.getList();
  },
  methods: {
    reloading() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    handleSelect(key) {
      // console.log( key);
      this.keys = key;
      // window.sessionStorage.setItem("webPage", this.keys);
      let path = this.$route.path;
      //   console.log(path);
      if (path !== "/academic") {
        this.$router.push({
          name: "Academic",
          query: { webPage: key },
        });
      }
      // if (this.keys == 1) {
      //   this.reloads();
      // }
      // if (this.keys == 2) {
      //   this.reloading();
      // }
    },
    update() {
      let user_id = {
        user_openid: localStorage.getItem("code"),
      };
      update_article(user_id)
        .then((res) => {
          // console.log("id",res);
          this.total = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 期刊列表
    getList() {
      journal_discipline(this.parameter)
        .then((res) => {
          // console.log(res);
          this.listData = res.data.data.data;
          this.listData.forEach((item) => {
            item.source = item.source.split(";;");
          });
          //   console.log(this.listData);
          this.totalCount = res.data.data.count;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 订阅期刊
    subscribe(state) {
      let id = {
        user_openid: localStorage.getItem("code"),
        journal_id: state.id,
      };
      if (state.isSubscribe == 0) {
        subscribe_subject(id)
          .then((res) => {
            console.log(res);
            this.$message({
              message: res.data.message,
              type: "success",
            });
            this.getList();
          })
          .catch((err) => {
            console.log(err);
          });
      }
      if (state.isSubscribe == 1) {
        cancel_subscribe(id)
          .then((res) => {
            console.log(res);
            this.$message({
              message: "订阅已取消",
              //   type: "success",
            });
            this.getList();
          })
          .catch((err) => {
            console.log(err);
          });
      }
      //   this.getList();
    },
    // 翻页
    handleCurrentChange(val) {
      this.parameter.page = val;
      this.getList();
      //   window.scrollTo(0, 0);
    },
  },
};
</script>

<style   lang="less" scoped>
.list {
  min-width: 1360px;
  .el-aside {
    background-color: #fff;
    color: #333;
    text-align: center;
    line-height: 200px;
  }

  .el-main {
    // height: 100%;
    background-color: #e9eef3;
    color: #333;
    // text-align: center;
    // line-height: 160px;
  }

  body > .el-container {
    margin-bottom: 40px;
  }

  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }

  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  }
  .el-menu {
    background-color: #fff;
  }
}
.is-active div {
  background-color: #B14A75;
  span {
    color: #fff;
  }
  i {
    color: #fff;
  }
}
.el-menu-item {
  padding: 5px 50px !important;
  background-color: #fff;
  margin-bottom: 5px;
}
.aside_item {
  width: 100%;
  height: 50px;
  line-height: 50px;
  border-radius: 5px;
  span {
    color: #b1aec8;
    font-size: 15px;
  }
}
.item {
  line-height: 30px;
  /deep/ .el-badge__content.is-fixed {
    position: absolute;
    top: 5px;
    right: 13px;
    transform: translateY(-50%) translateX(100%);
  }
}
.main_box {
  background: white;
  padding: 30px 60px 0 60px;
  //   width: 1320px;
  .bread {
    // line-height: 50px;

    display: flex;
    justify-content: space-between;
    .el-breadcrumb {
      line-height: 28px;
    }
  }
  .box {
    // min-width: 1200px;
    max-width: 1550px;
    margin: auto;
    .journal_list {
      margin: 30px 0;
      background: #ececec;
      border-radius: 10px;
      display: flex;
      padding: 10px 10%;
      // justify-content: space-between;
      .journal_img {
        margin-right: 50px;
        img {
          width: 150px;
          height: 205px;
          border-radius: 5px;
        }
      }
      .journal_data {
        width: 100%;
        //  max-width: 800px;
        // min-width: 500px;
        .title {
          width: 100%;
          // min-width: 300px;
          display: flex;

          justify-content: space-between;
        }
        h2:hover {
          color: #a0a0a0;
        }
        .tags {
          display: flex;
          margin: 10px 0;
          .el-tag {
            margin-right: 10px;
          }
        }
        p {
          line-height: 30px;
        }
      }
    }
  }
}
.page-bar {
  padding: 10px 0;
  display: flex;
  justify-content: center;
}
.subscribe_css {
  width: 50px !important;
  height: 50px !important;
  position: relative;
  left: -50px;
  //   top: -150px;
}
.imges {
  display: flex;
}
</style>