var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list"},[_c('el-container',{style:({ height: _vm.Height + 'px' })},[_c('el-main',[_c('div',{staticClass:"main_box"},[_c('div',{staticClass:"bread"},[_c('el-breadcrumb',{attrs:{"separator-class":"el-icon-arrow-right"}},[(_vm.$route.query.webPage == '1')?_c('el-breadcrumb-item',{attrs:{"to":{
                path: '/academic',
                query: {
                  webPage: _vm.$route.query.webPage,
                },
              }}},[_c('span',[_vm._v("中文期刊推荐")])]):_vm._e(),(_vm.$route.query.webPage == '2')?_c('el-breadcrumb-item',{attrs:{"to":{
                path: '/academic',
                query: {
                  webPage: _vm.$route.query.webPage,
                },
              }}},[_c('span',[_vm._v("英文期刊推荐")])]):_vm._e(),(_vm.$route.query.webPage == '3')?_c('el-breadcrumb-item',{attrs:{"to":{
                path: '/academic',
                query: {
                  webPage: _vm.$route.query.webPage,
                },
              }}},[_c('span',[_vm._v("个人中心")])]):_vm._e(),(_vm.$route.query.webPage == '4')?_c('el-breadcrumb-item',{attrs:{"to":{
                path: '/academic',
                query: {
                  webPage: _vm.$route.query.webPage,
                },
              }}},[_c('span',[_vm._v("机构订阅")])]):_vm._e(),_c('el-breadcrumb-item',[_vm._v(_vm._s(_vm.$route.query.subject)+"期刊")])],1),_c('router-link',{attrs:{"to":{ path: '/academic' }}},[_c('el-button',{attrs:{"type":"primary","size":"mini","plain":""}},[_vm._v("返回")])],1)],1),_c('el-divider'),_c('div',{staticClass:"box"},[_vm._l((_vm.listData),function(item,index){return _c('div',{key:index,staticClass:"journal_list"},[_c('div',{staticClass:"journal_img"},[_c('router-link',{attrs:{"to":{
                  path: '/journalsDetail',
                  query: { id: item.id, personalized: item.personalized ,subject: item.categories ,webPage: _vm.$route.query.webPage},
                }}},[_c('div',{staticClass:"imges"},[_c('img',{attrs:{"src":'https://push.newacademic.net/cover/journal_cover/' +
                      (item.language == 1 ? item.name : item.cn_name) +
                      '.jpg',"alt":""}}),(item.personalized == 1)?_c('img',{staticClass:"subscribe_css",attrs:{"src":require("../../../assets/imgs/icons/customized1.png"),"alt":""}}):_vm._e()])])],1),_c('div',{staticClass:"journal_data"},[_c('div',{staticClass:"title"},[(item.language == 1)?_c('router-link',{attrs:{"to":{
                    path: '/journalsDetail',
                    query: { id: item.id, personalized: item.personalized ,subject: item.categories,webPage: _vm.$route.query.webPage},
                  }}},[_c('h2',[_vm._v(" "+_vm._s(item.name)+" ")])]):_vm._e(),(item.language == 0)?_c('router-link',{attrs:{"to":{
                    path: '/journalsDetail',
                    query: { id: item.id, personalized: item.personalized ,subject: item.categories,webPage: _vm.$route.query.webPage},
                  }}},[_c('h2',[_vm._v(" "+_vm._s(item.cn_name)+" ")])]):_vm._e(),(item.isSubscribe == 0)?_c('el-button',{attrs:{"type":"primary","size":"mini","plain":""},on:{"click":function($event){return _vm.subscribe(item)}}},[_vm._v("订阅")]):_vm._e(),(item.isSubscribe == 1)?_c('el-popconfirm',{attrs:{"title":"确认取消订阅吗？"},on:{"confirm":function($event){return _vm.subscribe(item)}}},[_c('el-button',{staticStyle:{"background":"#b14a75","color":"white"},attrs:{"slot":"reference","type":"primary","size":"mini","plain":""},slot:"reference"},[_vm._v("已订阅")])],1):_vm._e()],1),_c('div',{staticClass:"tags"},_vm._l((item.source),function(item,index){return _c('div',{key:index},[(item == 'CA 化学文摘(美)(2020)')?_c('el-tag',[_vm._v("CA")]):_vm._e(),(
                      item == 'CSCD 中国科学引文数据库来源期刊(2021-2022年度)'
                    )?_c('el-tag',{attrs:{"type":"success"}},[_vm._v("CSCD")]):_vm._e(),(
                      item ==
                      'CSCD 中国科学引文数据库来源期刊(2021-2022年度)(扩展版)'
                    )?_c('el-tag',{attrs:{"type":"info"}},[_vm._v("CSCD(扩)")]):_vm._e(),(
                      item == 'CSSCI 中文社会科学引文索引(2021-2022)来源期刊'
                    )?_c('el-tag',{attrs:{"type":"warning"}},[_vm._v("CSSCI")]):_vm._e(),(item == 'CSTPCD')?_c('el-tag',{attrs:{"type":"danger"}},[_vm._v("CSTPCD")]):_vm._e(),(item == 'JST 日本科学技术振兴机构数据库(日)(2018)')?_c('el-tag',[_vm._v("JST")]):_vm._e(),(item == 'Pж(AJ) 文摘杂志(俄)(2020)')?_c('el-tag',{attrs:{"type":"success"}},[_vm._v("Pж(AJ) ")]):_vm._e(),(item == 'SA 科学文摘(英)(2020)')?_c('el-tag',{attrs:{"type":"info"}},[_vm._v("SA")]):_vm._e(),(
                      item ==
                      'WJCI 科技期刊世界影响力指数报告（2020）来源期刊'
                    )?_c('el-tag',{attrs:{"type":"warning"}},[_vm._v("WJCI")]):_vm._e(),(item == '北大核心')?_c('el-tag',{attrs:{"type":"danger"}},[_vm._v("北大核心")]):_vm._e(),(item == 'EI 工程索引(美)(2020)')?_c('el-tag',{attrs:{"type":"warning"}},[_vm._v("EI")]):_vm._e(),(item == 'SCI 科学引文索引(美)(2020)')?_c('el-tag',{attrs:{"type":"danger"}},[_vm._v("SCI")]):_vm._e()],1)}),0),_c('p',[_vm._v("创办单位："+_vm._s(item.press))]),_c('p',[_vm._v("出版周期："+_vm._s(item.frequency))]),(item.create_time)?_c('p',[_vm._v(" 创建时间："+_vm._s(_vm._f("parseTime")(item.create_time,"{y}-{m}-{d}"))+" ")]):_vm._e(),_c('p',[_vm._v(" 最新更新时间："+_vm._s(_vm._f("parseTime")(item.update_time,"{y}-{m}-{d}"))+" ")])])])}),(_vm.totalCount > 0)?_c('div',{staticClass:"page-bar"},[_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","total":_vm.totalCount > 10000 ? 10000 : _vm.totalCount,"page-size":10},on:{"current-change":_vm.handleCurrentChange}})],1):_vm._e()],2)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }